<template>
  <b-table-filter
    :items="rows"
    :fields="fields"
    title="Danh Sách Phụ Huynh"
    :totalRows="rowsLength"
    :currentPage="currentPage"
    :perPage="perPage"
    :filterOn="filterOn"
    :filter="filter"
    :filterDateCreatedFrom="filterDateCreatedFrom"
    :filterDateCreatedTo="filterDateCreatedTo"

    @remove-act="removeItem"
  >
    <template #filterAdvanced>
      <b-row>
        <b-col cols="7">
          <b-row no-gutters>
            <b-col cols="4">
              <b-form-group
                label="Tìm Phụ Huynh"
              >
                <div class="ft-class-list-opt">
                  <b-form-input @change="filterAdvanced('parent', $event)" placeholder="Tìm..."/>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                label="Tìm Email"
              >
                <div class="ft-class-list-opt">
                  <b-form-input @change="filterAdvanced('email', $event)" placeholder="Tìm..."/>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                label="Tìm Số Điện Thoại"
              >
                <div class="ft-class-list-opt">
                  <b-form-input @change="filterAdvanced('phone', $event)" placeholder="Tìm..."/>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="4" offset="1">
          <b-row no-gutters>
            <b-col cols="6">
              <div class="ft-class-list-opt">
                <b-form-group
                  label="Ngày Tạo"
                >
                  <b-form-datepicker
                    v-model="filterDateFrom"
                    @input="filterAdvanced('tu_ngay')"
                    placeholder="Từ Ngày"
                    :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                  />
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="6">
              <div class="ft-class-list-opt">
                <b-form-group
                  label="Đến Ngày"
                >
                  <b-form-datepicker
                    v-model="filterDateTo"
                    @input="filterAdvanced('den_ngay')"
                    placeholder="Đến Ngày"
                    :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                    :date-disabled-fn="dateDisabledAdvanced"
                  />
                </b-form-group>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </template>
    <template #filterTable>
      <td>
        <b-form-input size="sm"
          class="ft-search-form-them-khoa-hoc"
          placeholder="Tìm Phụ Huynh"
          @keyup="columnFilter('parent')"
          v-model="filterObj['parent']"
        />
      </td>
      <td>
        <b-form-input size="sm"
          class="ft-search-form-them-khoa-hoc"
          placeholder="Tìm Số Điện Thoại"
          @keyup="columnFilter('phone')"
          v-model="filterObj['phone']"
        />
      </td>
      <td>
        <b-form-input size="sm"
          class="ft-search-form-them-khoa-hoc"
          placeholder="Tìm Email"
          @keyup="columnFilter('email')"
          v-model="filterObj['email']"
        />
      </td>
      <td>
        <b-form-input size="sm"
          class="ft-search-form-them-khoa-hoc"
          placeholder="Tìm Tỉnh Thành"
          @keyup="columnFilter('province')"
          v-model="filterObj['province']"
        />
      </td>
      <td>
        <b-form-input size="sm"
          class="ft-search-form-them-khoa-hoc"
          placeholder="Tìm Quận Huyện"
          @keyup="columnFilter('district')"
          v-model="filterObj['district']"
        />
      </td>
      <td>
        <b-form-input size="sm"
          class="ft-search-form-them-khoa-hoc"
          placeholder="Tìm Phường Xã"
          @keyup="columnFilter('ward')"
          v-model="filterObj['ward']"
        />
      </td>
      <td>
        <b-form-datepicker size="sm"
          v-model="filterDateCreatedFrom"
          placeholder="Từ Ngày"
          :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
        />
      </td>
      <td>
        <b-form-datepicker  size="sm"
          v-model="filterDateCreatedTo"
          placeholder="Đến Ngày"
          :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
          :date-disabled-fn="dateDisabled"
        />
      </td>
    </template>
  </b-table-filter>
</template>

<script>
import {
  BFormGroup, BButton, BCol, BRow, BFormInput, BFormDatepicker, BContainer, 
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { formatDate } from '@/@core/utils/filter'
import BTableFilter from '@/@core/components/datatable/BTableFilter.vue'
import VueSelect from 'vue-select'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BCol,
    BRow,
    VueSelect,
    FeatherIcon,
    BTableFilter,
    BContainer,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      fields: [
        {
          label: 'Họ Tên Phụ Huynh',
          key: 'parent',
        },
        {
          label: 'Số Học Viên',
          key: 'studentCount',
        },
        {
          label: 'Số Điện Thoại',
          key: 'phone',
        },
        {
          label: 'Số Điện Thoại Phụ',
          key: 'phone2',
        },
        {
          label: 'Số Email',
          key: 'email',
        },
        {
          label: 'Phân Loại',
          key: 'parentType',
        },
        {
          label: 'Tỉnh Thành',
          key: 'province',
        },
        {
          label: 'Quận Huyện',
          key: 'district',
        },
        {
          label: 'Phường Xã',
          key: 'ward',
        },
        {
          label: 'Ngày Đăng Ký',
          key: 'createdAt',
        },
      ],
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filterKhoaHoc: null,
      filterLopHoc: null,
      filterGiaoVien: null,
      filterTeacherAt: null,
      filter: null,
      filterOn: [], // danh sách cột
      count: 0,
      filterDateCreatedFrom: null,
      filterDateCreatedTo: null,
      filterDateFrom: null,
      filterDateTo: null,
      filterParent: null,
      selected: [],
      filterObj: {},
      items: [
        {
          id: 1,
          parent: 'Danh Sinh 1',
          studentCount: '1',
          createdAt: 1658925621,
          phone: '096 998 5002',
          phone2: '096 998 5002',
          email: 'mr.hanleve@gmail.com',
          parentType: 'mr.hanleve@gmail.com',
          province: 'Kiên Giang',
          address: 'ấp Hòa Bình',
          district: 'Gò Quao',
          ward: 'Xã Thới Quản',
        },
        {
          id: 2,
          parent: 'Danh Sinh 2',
          studentCount: '1',
          createdAt: 1658925621,
          phone: '096 998 5002',
          phone2: '096 998 5002',
          email: 'mr.hanleve@gmail.com',
          parentType: 'mr.hanleve@gmail.com',
          province: 'Kiên Giang',
          address: 'ấp Hòa Bình',
          district: 'Gò Quao',
          ward: 'Xã Thới Quản',
        },
        {
          id: 3,
          parent: 'Danh Sinh 2',
          studentCount: '1',
          createdAt: 1658925621,
          phone: '096 998 5002',
          phone2: '096 998 5002',
          email: 'mr.hanleve@gmail.com',
          parentType: 'mr.hanleve@gmail.com',
          province: 'Kiên Giang',
          address: 'ấp Hòa Bình',
          district: 'Gò Quao',
          ward: 'Xã Thới Quản',
        },
        {
          id: 4,
          parent: 'Danh Sinh 3',
          studentCount: '1',
          createdAt: 1658925621,
          phone: '096 998 5002',
          phone2: '096 998 5002',
          email: 'mr.hanleve@gmail.com',
          parentType: 'mr.hanleve@gmail.com',
          province: 'Kiên Giang',
          address: 'ấp Hòa Bình',
          district: 'Gò Quao',
          ward: 'Xã Thới Quản',
        },
      ],
    }
  },
  mounted() {
    document.title = `Quản lý Lớp | ${this.route.meta.pageTitle}`
  },
  computed: {
    rowsLength() {
      return this.items.length;
    },
    rows() {
      return this.items.map((item, k) => {
        return {
          parent: item.parent + ' ' + k,
          studentCount: item.studentCount + ' ' + k,
          createdAt: 1658925621,
          phone: item.phone + ' ' + k,
          phone2: item.phone2 + ' ' + k,
          email: item.email + ' ' + k,
          parentType: item.parentType + ' ' + k,
          province: item.province + ' ' + k,
          address: item.address + ' ' + k,
          district: item.district + ' ' + k,
          ward: item.ward + ' ' + k,
        }
      })
    }
  },
  methods: {
    removeItem(id, k) {
      console.log('remove', id, k)
    },
    filterAdvanced(t, $event) {
    },
    dateDisabled(ymd, date) {
      const day = date.getDate()
      return day < new Date(new Date(this.filterDateCreatedFrom).getDate())
    },
    dateDisabledAdvanced(ymd, date) {
      const day = date.getDate()
      return day < new Date(new Date(this.filterDateFrom).getDate())
    },
    columnFilter(t) {
      this.filterOn = [t]
      this.filter = this.filterObj[t]
    },
    onRowSelected(rows) {
      this.count = rows.length
      this.selected = rows
    },
  },
}
</script>

<style>
.ft-class-list-opt{
  margin-right: 10px;
}
</style>